import uajs from './module/ua';
// 共通

(function ($) {
	const ua = uajs();

	$(document).ready(function () {

		// アンカーリンク
		$('a[href*="#"]').click(function (e) {
			const href = $(this).attr('href');
			if (href.match(/^#/) && $(href).length > 0) {
				e.preventDefault();
				$('html, body').animate({
					scrollTop: $(href).offset().top
				}, 'fast');
				return false;
			}
		});

		// Gnav class追加、スクリーンリーダーでbuttonの開閉を認識
		$('.l-nav__btn.js-gnav').click(function (e) {
			if (window.innerWidth <= 767) {
				e.preventDefault();
				$(".l-header__contents").toggleClass("l-nav__contents-open");
				if ($(this).attr('aria-expanded') == 'true') {
					$(this).attr('aria-expanded', 'false');
					$(".l-nav__body").attr('aria-hidden', 'true');
				} else {
					$(this).attr('aria-expanded', 'true');
					$(".l-nav__body").attr('aria-hidden', 'false');
				}
			}
		});

		$(window).on('load resize', function () {
			if (window.innerWidth <= 767) {
				$(".l-nav__body").attr('aria-hidden', 'true');
			} else {
				$(".l-nav__body").attr('aria-hidden', 'false');
			}
		});

		// Gnav SPでページ内リンクをクリックしたらハンバーガーメニューを閉じる
    $('.l-nav__link').on('click', function () {
      $(".l-header__contents").removeClass('l-nav__contents-open');
      if (window.innerWidth <= 767) {
        if ($(".l-nav__btn.js-gnav").attr('aria-expanded') == 'false') {
          $(".l-nav__btn.js-gnav").attr('aria-expanded', 'true');
          $(".l-nav__body").attr('aria-hidden', 'false');
        } else {
          $(".l-nav__btn.js-gnav").attr('aria-expanded', 'false');
          $(".l-nav__body").attr('aria-hidden', 'true');
        }
      }
    });

		// スクロールで要素にclass追加
    $(window).on('scroll', function () {
      $(".js-anime").each(function(){
        var position = $(this).offset().top;
        var scroll = $(window).scrollTop();
        var windowHeight = $(window).height();
        if (scroll > position - windowHeight + windowHeight / 7) {
          $(this).addClass('js-active');
        }
      });
		});

		// イベント見出し高さ揃え
		$(".page-event__text .js-ttl-height").heightLine({
			minWidth:768
		});


	});

})(jQuery);
